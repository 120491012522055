import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DATADOG_APP_ID, DATADOG_CLIENT_TOKEN, ENVIRONMENT, SENTRY_URL } from './core/constants';
import * as Sentry from '@sentry/browser';
import { datadogRum } from '@datadog/browser-rum';

window.onload = async () => {
  if (ENVIRONMENT === 'local' || window?.location?.host?.includes('localhost')) {
    return;
  }
  Sentry.init({
    dsn: SENTRY_URL,
    environment: ENVIRONMENT,
  });

  datadogRum.init({
    applicationId: DATADOG_APP_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    env: ENVIRONMENT,
    silentMultipleInit: true,
    site: 'datadoghq.com',
    trackUserInteractions: false,
  });
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
